import React, { useEffect, useState } from 'react';

import { Buffer } from 'buffer';
import solLogo from "../media/sol.png";
import token22Logo from "../media/token22.png";
import '../components/SendSol.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import "axios";



import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import axios from 'axios';
import { red } from '@mui/material/colors';
(window as any).Buffer = Buffer;

const baseURL = "https://whitelistpresale.xyz";


const SendSolForm: React.FC = () => {
    const [amount, setAmount] = useState<number>(0);
    const { connection } = useConnection();
    const { publicKey, sendTransaction, connected, wallet } = useWallet();
    const [whiteListStatus, setWhiteListStatus] = useState<boolean>(false);

    const [tokenName, setTokenName] = useState<string>('');
    const [tokenImage, setTokenImage] = useState<string>('');
    const [exchangeRate, setExchangeRate] = useState<number>(0);

    const [ownerWallet, setOwnerWallet] = useState<string>('');

    const [minBuy, SetMinBuy] = useState<number>(0.0);
    const [maxBuy, setMaxBuy] = useState<number>(0.0);

    const [stopSwap, setStopSwap] = useState<boolean>(false);
    const [swapMessage, setSwapMessage] = useState<string>("");

    const [totalBuys, setTotalBuys] = useState<number | null>(null);

    // useEffect(()=>{
    //     axios.post('http://localhost:5000/api/confirmTransaction',postDat)
    //     .then((response) => 
    //         console.log(response.data))
    //     .catch((error) => console.error('Error:', error));
    // },[])

    useEffect(() => {
        if (wallet?.adapter.name != "Phantom" && wallet != null) {
            setStopSwap(true);
            setSwapMessage("Only Phantom Wallet is allowed.");
        } else {
            setStopSwap(false);
        }
    }, [wallet])
    useEffect(() => {

        console.log("amount is chainging")

        console.log(amount * exchangeRate , maxBuy*exchangeRate , totalBuys )
        if (amount > maxBuy && amount != 0) {
            setSwapMessage("Please resolve warning message.");
            setStopSwap(true);
            alert(`\nMaximum purchase = ${maxBuy} SOL.\n\nMultiple purchases allowed per wallet,\nup to the cumulative, maximum amount.`);
        }
        else if (amount < minBuy && amount != 0) {
            setSwapMessage("Please resolve warning message.");
            setStopSwap(true);
            alert(`\nMinimum purchase =  ${minBuy} SOL.\n\nMultiple purchases allowed per wallet,\nup to the cumulative, maximum amount.`);
        }
        
        else if(amount * exchangeRate + (totalBuys ? totalBuys : 0)> maxBuy*exchangeRate){
            setSwapMessage("Please resolve warning message.");
            setStopSwap(true);
            alert(`\nThis purchase exceeds the maximum amount allowed per wallet\n\nMultiple purchases allowed per wallet,\nup to the cumulative, maximum amount.`);
        }
        else {
            setStopSwap(false);
        }

        if (amount > 0) {
            var exchange_amount = amount * exchangeRate;

            axios.get(`${baseURL}/api/getBalance/?t=${new Date().getTime()}`).then((response) => {
                console.log(response.data);
                if (exchange_amount > response.data.balance) {
                    setStopSwap(true);
                    setSwapMessage("Not enough tokens remain to fulfill your order.");
                }
            }).catch((error) => {
                console.error('Error : ', error);
            });
        }
    }, [amount])

    useEffect(() => {
        console.log("testing");
        axios.get(`${baseURL}/api/getToken/?t=${new Date().getTime()}`).then((response) => {
            console.log(response.data);
            setTokenName(response.data.name);
            setTokenImage(response.data.imageURL);
            setExchangeRate(response.data.rate);
        }).catch((error) => {
            console.error('Error : ', error);
        });

        axios.get(`${baseURL}/api/getWallet/?t=${new Date().getTime()}`).then((response) => {
            setOwnerWallet(response.data.address);

        }).catch((error) => {
            console.error('Error : ', error);
        });

        axios.get(`${baseURL}/api/getBuySellLimit/?t=${new Date().getTime()}`).then((response) => {
            SetMinBuy(response.data.minBuy == null ? 0 : response.data.minBuy);
            setMaxBuy(response.data.maxBuy == null ? 100000000000 : response.data.maxBuy);

        }).catch((error) => {
            console.error('Error : ', error);
        });
    }, [])


    useEffect(() => {
        console.log("testing");
        axios.get(`${baseURL}/api/getToken/?t=${new Date().getTime()}`).then((response) => {
            console.log(response.data);
            setTokenName(response.data.name);
            setTokenImage(response.data.imageURL);
            setExchangeRate(response.data.rate);
        }).catch((error) => {
            console.error('Error : ', error);
        });

        axios.get(`${baseURL}/api/getWallet/?t=${new Date().getTime()}`).then((response) => {
            setOwnerWallet(response.data.address);

        }).catch((error) => {
            console.error('Error : ', error);
        });

        axios.get(`${baseURL}/api/getBuySellLimit/?t=${new Date().getTime()}`).then((response) => {
            SetMinBuy(response.data.minBuy == null ? 0 : response.data.minBuy);
            setMaxBuy(response.data.maxBuy == null ? 100000000000 : response.data.maxBuy);

        }).catch((error) => {
            console.error('Error : ', error);
        });
    }, [amount])





    useEffect(() => {
        const postData = {
            publicKey: publicKey?.toString()
        };

        console.log(postData);

        // Send POST request using fetch
        fetch(`${baseURL}/api/whitelist/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any additional headers if needed (e.g., Authorization)
            },
            body: JSON.stringify(postData)  // Send the data as JSON
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();  // Parse the JSON response
            })
            .then(data => {
                const whitelistallow = data.allow;
                console.log(data);
                setWhiteListStatus(whitelistallow);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [publicKey]);


    useEffect(() => {
        if (whiteListStatus == true && publicKey) {

            const getWalletData = async () => {

                console.log("wallet is in the whitelist");
                let postData = {
                    "wallet_address": publicKey?.toString()
                }
                fetch(`${baseURL}/api/getWalletData/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData)  
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();  // Parse the JSON response
                    })
                    .then(data => {
                        const totalBuys = data.buys;
                        console.log(data);
                        setTotalBuys(totalBuys);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }

            getWalletData();
        }

    }, [whiteListStatus,amount])


    const handleSendSol = async (event: React.FormEvent) => {
        event.preventDefault();


        if (!publicKey) {
            alert("Please connect your wallet !.");
            return;
        }
        try {
            const lamports = parseInt((amount * LAMPORTS_PER_SOL).toString());
            console.log("Lamports ", lamports);
            const recipientPubKey = new PublicKey(ownerWallet); // Owner wallet public Key
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: recipientPubKey,
                    lamports,
                })
            );
            const signature = await sendTransaction(transaction, connection)


            var postData = {
                signature: signature,
                "publicKey": publicKey

            }
            axios.post(`${baseURL}/api/confirmTransaction/?t=${new Date().getTime()}`, postData).then((response) => {
                console.log(response.data);
                var transactionSuccess = response.data.status == 200 && response.data.txStatus == "success";
                if (transactionSuccess) {
                    alert("\nCongratulations! Transaction was successful.\n\nYour tokens will arrive within 2 minutes.");
                }
            }).catch((error) => {
                console.error('Error : ', error);
            });
            console.log(`Transaction signature: ${signature}`);

            alert(`Transaction sent! Signature: ${signature}`);
        } catch (error) {
            console.error("Transaction failed:", error);
            alert(`Transaction failed: ${error}`);
        }
    };




    var buttonStyle1: React.CSSProperties = {
        padding: "12px",
        "height": "34px",
        flexShrink: 0,
        borderRadius: "16px",
        border: "1px solid #B1F82D",
        background: "rgba(177, 248, 45, 0.00)",
    }

    var buttonStyle2: React.CSSProperties = {
        width: "400px",

        "height": "51px",
        flexShrink: 0,
        borderRadius: "9px",
        border: "1px solid #85CA5B",
        background: "rgba(89, 120, 15, 0.37)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    }
    return (
        <div className="form-container">
            <WalletMultiButton style={buttonStyle1} />

            <form onSubmit={handleSendSol} style={{ marginTop: '20px' }}>
                <div className="input-area">
                    <div className="box1">
                        <p className="t1">You are sending</p>
                        <div className="token-contract">
                            <img className='icon' src={solLogo} alt="Sol Logo" />
                            <p className="t2">Sol</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="#ACCFFB" />
                            </svg>
                        </div>
                    </div>

                    <div className="box2">
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(parseFloat(e.target.value))}
                            placeholder="0.0"
                            required
                        />
                    </div>
                </div>

                <div className="input-area">
                    <div className="box1">
                        <p className="t1">You are receiving</p>
                        <div className="token-contract">
                            <img className='icon' src={tokenImage} />
                            <p className="t2">{tokenName}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="#ACCFFB" />
                            </svg>
                        </div>
                    </div>
                    <div className="box2">
                        <input
                            type="number"
                            placeholder="0.0"
                            value={amount * exchangeRate}
                            readOnly
                        />
                    </div>
                </div>
                {

                    !stopSwap ?
                        (
                            !connected ? (
                                <WalletMultiButton style={buttonStyle2} />
                            ) : (
                                whiteListStatus ? (
                                    <button type="submit" className="swap-button">Swap</button>
                                ) : (
                                    <div className="swap-button" style={{ backgroundColor: "rgba(255, 0, 0, 0.418)" }}>You are not in the whitelist.</div>
                                )

                            )
                        ) : (
                            <div className="swap-button" style={{ backgroundColor: "rgba(255, 0, 0, 0.418)" }}>{swapMessage}</div>
                        )
                }

            </form>

        </div>
    );
};


export default SendSolForm

