import React from 'react';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import SendSolForm from './components/SendSolForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import ClaimAirdrop from './components/claimAirdrop';
const App: React.FC = () => {
    const endpoint = "https://ultra-proportionate-diagram.solana-mainnet.quiknode.pro/307e93fe73285ce065f52b220b0d39ffcfa32092/";
    return (



        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={[]} autoConnect>
                <WalletModalProvider>
                    <Router>
                        <Routes>
                            <Route path="/" element={<SendSolForm />} />
                            <Route path="/claim" element={<ClaimAirdrop />} />
                            <Route path='/admin/login' element={<AdminLogin/>}/>
                        </Routes>
                    </Router>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default App;